@import "../../../index.scss";

.container {
	@include container;
	.header {
		@include header;
	}
	.subheader {
		@include subheader;
	}
}

.labels {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-weight: 700;
	margin-bottom: $gap;
}

.perfil-select {
	margin-bottom: $gap2x;
}

.perfil-table-container {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 700px;
	margin: auto;
	margin-top: 50px;
}

.perfil-table {
	margin: $gap;
}

.perfil-cell {
	align-items: center;
	justify-content: center;
}

#buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	margin: $gap;
	gap: $gap;
	.button {
		@include primary-button;
	}
}
