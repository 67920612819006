@import "../../index.scss";

.dashboard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	> .title {
		font-size: $h1-font-size;
		font-weight: $font-weight-bold;
		color: $secondaryColor;
		margin-top: $gap2x;
		margin-bottom: $gap;
	}

	.subtitle {
		font-size: $h2-font-size;
		font-weight: $font-weight-bold;
		color: $secondaryColor;
		margin-top: $gap;
		margin-bottom: $gap;
	}

	#buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		margin: $gap;
		gap: $gap;
		.button {
			width: 150px;
			height: 90px;
			@include primary-button;
		}
	}

	#tertiary-buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		margin: $gap;
		gap: $gap;
		.button {
			width: 180px;
			height: 100px;
			@include tertiary-button;
		}
	}
}
