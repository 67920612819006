@import "../../../../index.scss";

.home {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&__title {
		font-size: $h1-font-size;
		font-weight: 700;
		color: #333;
		margin-top: $gap;
	}
	&__subtitle {
		font-size: $h2-font-size;
		font-weight: 400;
		color: #333;
		margin-top: $gap3x;
	}
	&__logo {
		width: 200px;
		margin: $gap2x;
	}
}
