@import "../../../index.scss";

.container {
	@include container;
	.header {
		@include header;
	}
	.subheader {
		@include subheader;
	}
}

#botonera {
	.primary-button {
		@include primary-button;
	}
	.secondary-button {
		@include secondary-button;
	}
}
