@import "../../../../index.scss";

.container {
	@include container;
	justify-content: center;
	align-items: center;
	.header {
		@include header;
	}
	.subheader {
		@include subheader;
	}

	.images {
		display: flex;
		// flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		// border: 1px solid $primaryColor;
		// border-radius: 5px;
		margin: $gap;

		img {
			padding: $gap 50px;
			width: 100%;
		}

		.action-button {
			margin: $gap;
		}

		.text {
			display: flex;
			justify-content: center;
			margin: $gap;
			font-size: $h3-font-size;
		}
	}

	.return-button {
		display: flex;
		justify-content: center;
		align-items: center;
		// margin-bottom: 50px !important;
	}
}
