@import "../../../index.scss";

.container {
	@include container;
	.header {
		@include header;
	}
	.subheader {
		@include subheader;
	}
}

.captura {
	.table {
		margin: $gap;
	}

	#buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		margin: $gap;
		gap: $gap;
		.button {
			@include primary-button;
		}
	}
}
