@import "../../../../index.scss";

.container {
	@include container;
	.header {
		@include header;
	}
	.subheader {
		@include subheader;
	}
}

.nuevo-estudio {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 500px;
	margin: auto;

	.header {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: $gap;
		font-size: large;
	}

	> .form {
		margin: 10px;
		> .subtitle {
			font-size: $h2-font-size;
			margin-bottom: 20px;
		}

		> .input {
			text-align: left;
		}
	}

	> .text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 10px;
		font-size: $h3-font-size;
	}

	#primary-button {
		@include primary-button;
	}

	#secondary-button {
		@include secondary-button;
	}
}
