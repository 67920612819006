// General color schema
$red: #b03060;
$orange: #fe9a76;
$yellow: #ffd700;
$olive: #32cd32;
$green: #016936;
$teal: #008080;
$lightblue: #00bfff;
$blue: #0091ff;
$darkblue: #0067f8;
$darkerblue: #0033cc;
$violet: #ee82ee;
$purple: #b413ec;
$pink: #ff1493;
$brown: #a52a2a;
$grey: #c2c2c2;
$darkgrey: #696969;
$darkergrey: #343434;
$black: #000000;
$white: #ffffff;

$primary_brand: #df9f00;
$secondary_brand: #373a40;
$tertiary_brand: #fff;

$primaryColor: $primary_brand;
$secondaryColor: $secondary_brand;
$tertiaryColor: $tertiary_brand;

$textColor: $secondary_brand;
$invertedTextColor: $secondary_brand;

$link-color: $primary_brand;
$link-color-hover: $secondary_brand;
$link-font-size: 15px;

// Headers
$h1-font-size: 25px;
$h2-font-size: 20px;
$h3-font-size: 15px;

// Buttons
$button-font-size: 13px;

$button-primary-color: $invertedTextColor;
$button-primary-color-hover: $invertedTextColor;
$button-primary-background-color: $primaryColor;
$button-primary-background-color-hover: $secondary_brand;

$button-secondary-color: $invertedTextColor;
$button-secondary-color-hover: $invertedTextColor;
$button-secondary-background-color: $darkgrey;
$button-secondary-background-color-hover: $darkergrey;

$button-tertiary-color: $textColor;
$button-tertiary-color-hover: $invertedTextColor;
$button-tertiary-background-color: $tertiaryColor;
$button-tertiary-background-color-hover: $secondary_brand;

// Fonts
$font-family: "Noto Sans", sans-serif;
$font-family-title: "Noto Sans", sans-serif;
$font-size: 14px;
$font-size-label: 14px;
$font-size-h1: 25px;
$font-size-h2: 20px;
$font-size-h3: 15px;
$font-size-header: 18px;
$font-weight: 400;
$font-weight-bold: 700;

// Spacing
$gap: 0.5em;
$gap2x: 1em;
$gap3x: 1.5em;
$gap4x: 2em;

body,
html {
	height: 100vh;
}

@mixin primary-button {
	font-size: $button-font-size;
	color: $button-primary-color;
	background-color: $button-primary-background-color;
	transition: all 0.3s ease-in-out;
	margin: $gap;
	padding: 15px 20px;
	&:hover {
		color: $button-primary-background-color;
		background-color: $button-primary-color;
	}
}

@mixin secondary-button {
	font-size: $button-font-size;
	color: $button-secondary-color;
	background-color: $button-secondary-background-color;
	transition: all 0.3s ease-in-out;
	margin: $gap;
	padding: 15px 20px;
	&:hover {
		color: $button-primary-background-color;
		background-color: $button-secondary-color;
	}
}

@mixin tertiary-button {
	font-size: $button-font-size;
	color: $button-tertiary-color;
	background-color: $button-tertiary-background-color;
	transition: all 0.3s ease-in-out;
	margin: $gap;
	padding: 15px 20px;
	&:hover {
		color: $button-tertiary-color-hover;
		background-color: $button-tertiary-background-color-hover;
	}
}

@mixin error-message {
	color: $red;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
}

@mixin container {
	margin: $gap2x;
}

@mixin header {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: $gap;
	font-size: x-large;
}

@mixin subheader {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: $gap;
	font-size: large;
}

@mixin subsubheader {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: $gap;
	font-size: medium;
}

@mixin botonera {
	display: flex;
	justify-content: space-around;
	margin-top: $gap2x;
}

#botonera,
#botonera1,
#botonera2,
#botonera3 {
	@include botonera;
}
